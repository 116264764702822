<template>
    <div class="container-window mt-3 mr-3 border bd-e4 px-3">
        <HeaderWindow @onClose="onClose" :title="title" :show_calll="true" />
        <div class="bg-white pt-3">
            <PersonProfile v-if="person_disease" :person="person_disease && person_disease.person" :disease="person_disease" :monitor_stats="monitor_stats"></PersonProfile>
        </div>
        <TypeBar v-if="person_disease" :showing="show" :person_id="person_disease.person_id" :disease="person_disease" />
        <div>
            <div class="table-responsive">
                <table class="table table-vcenter table-mobile-md card-table table-scroll">
                    <thead class="d-none d-md-table bg--primary-corl">
                        <tr class="">
                            <th class="robo-20-500 text-sm-center" style="background-color: #E7EAF5 !important;"><span class="txt-pri-corl">Tài liệu</span></th>
                            <th class="robo-20-500 text-sm-center" style="background-color: #E7EAF5 !important;"><span class="txt-pri-corl">Ngày</span></th>
                            <th class="robo-20-500 text-sm-center" style="background-color: #E7EAF5 !important;"><span class="txt-pri-corl">Người tải lên</span></th>
                            <th class="robo-20-500 text-sm-center" style="background-color: #E7EAF5 !important;"><span class="txt-pri-corl">Loại tài liệu</span></th>
                            <th class="robo-20-500 text-sm-center" style="background-color: #E7EAF5 !important;">
                                <span class="txt-pri-corl">Hành động</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <div v-if="documents && documents.count > 0" v-for="(doc, index) in documents.data" class="border-0">
                            <tr class="border-0">
                                <td data-label="Tài liệu" class="table-td-name-avatar robo-20-400">
                                    <span class="txt-pri-corl">{{doc && doc.content}}</span>
                                </td>
                                <td data-label="Ngày" style="text-transform: capitalize;" class="robo-20-400 text-sm-center">
                                    <span class="txt-pri-corl">{{formatDMY(doc.created_at)}}</span>
                                </td>
                                <td data-label="Người tải lên" class="table-td-action text-sm-center">
                                    <p class="mb-0"><span class="txt-pri-corl">{{doc.doctor ? doc.doctor.name : doc.person ? doc.person.name : ''}}</span></p>
                                </td>
                                <td data-label="Loại tài liệu" class="table-td-action text-sm-center">
                                    <p class="mb-0"><span class="txt-pri-corl" v-if="doc">{{doc.type === 1 ? 'Sổ khám bệnh' : doc.type === 2 ? 'Thông tin thuốc' : doc.type === 3 ? 'Xét nghiệm' : 'Sổ khám bệnh'}}</span></p> 
                                </td>
                                <td data-label="Hành động" class="table-td-action text-sm-center">
                                    <a download target="_blank" :href="getLinkDownload(doc.link)" :download="doc.name"><img src="../../../public/assets/images/icon/dowload.svg" height="24" width="24" alt="" class="cursor-pointer"></i></a>
                                </td>
                            </tr>
                        </div>
                        <tr v-if="!documents || (documents &&documents.count === 0)">
                            <td colspan="5">
                                <div class="col-sm-12 robo-14-400 text-center">Không có dữ liệu</div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <Pagination v-show="documents && documents.count" :items="documents" :current_page="current_page" @onRefresh="onRefresh"></Pagination>
            </div>
        </div>
    </div>
</template>
<script>
import PersonProfile from '../../components/Diseases/PersonProfile.vue'
import HeaderWindow from '../../components/Diseases/HeaderWindow.vue'
import TypeBar from '../../components/Cms/TypeBar.vue'
import Pagination from '../../components/Pagination'
import appUtils from '../../utils/appUtils'
export default {

  name: 'DocumentCareplanListing',
  components: { PersonProfile, HeaderWindow, TypeBar, Pagination },
  data () {
    return {
      type_date: null,
      dates: {
        start: new Date(),
        end: new Date()
      },
      show: 'documents',
      title: 'Tài liệu nguồn',
      person_id: null,
      file: null,
      content: '',
      documents: [],
      current_page: 1,
      loading: false,
      person_disease: null,
      monitor_stats: null
    }
  },
  mounted () {
    let self = this
    self.getPersonsDiseases()
    self.getStats()
  },
  methods: {
    onClose () {
      this.$router.push({ path: '/admin/care-plan' })
    },
    async getPersonsDiseases () {
      let self = this
      try {
        await self.$rf.getRequest('AdminRequest').getPersonsDiseasesById(self.$route?.params?.id).then(res => {
          if (res && res.data) {
            self.person_disease = res.data
            self.person_id = self.person_disease.person_id
            self.getPersonsDocuments(self.person_id)
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    onRefresh () {
      let self = this
      self.getPersonsDocuments(self.person_id)
    },
    async getPersonsDocuments (person_id) {
      let self = this
      self.current_page = parseInt(self.$route.query.page) || 1
      let params = {
        person_diseases_id: self.$route?.params?.id,
        sort: 'created_at',
        sort_by: 'desc',
        page: self.current_page
      }
      await self.$rf.getRequest('AdminRequest').getPersonsDocuments(person_id, params).then((res) => {
        self.documents = res.data
      })
    },
    onSelectFile (event) {
      this.file = event.target.files[0]
    },
    changeContent (txt) {
      this.content = txt
    },
    formatDMY (date) {
      return window.moment(date).format('DD/MM/YYYY')
    },
    getLinkDownload (link) {
      return appUtils.getDocumentURL(link)
    },
    async getStats () {
      let self = this
      let params = {}
      self.monitor_stats = []
      await self.$rf.getRequest('AdminRequest').getSurvivalStatSetting(self.$route?.params?.id, params).then(resp => {
        if (resp && resp.data && resp.data.count) {
          let stats = resp.data.data
          stats.forEach(function (stat, index) {
            if (stat.is_monitor === 1) {
              self.monitor_stats.push(stat)
            }
          })
        }
      })
    }
  }
}
</script>
<style lang="css" scoped>
</style>