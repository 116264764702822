<template>
  <div class="row">
    <div class="telemedicine-management pr-3">
      <div class="row pl-3 bg-white">
        <div class="col-6">
          <div class="d-flex py-1 align-items-center">
            <img src="https://img.icons8.com/ios/50/20419B/environment-care.png"/><span class="txt-pri page-title">Hồ sơ sức khỏe (Thông tin sức khoẻ)</span>
          </div>
        </div>
        <div class="col-6 mb-1">
          <div class="d-flex justify-content-end">
            <div class="row ml-auto">
              <div class="col-auto mt-2">
                <input class="form-control h-100" type="text" v-model="search" @keyup.enter="onSearch()" @keyup.delete="onSearchDelete()">
              </div>
              <div class="col-auto">
                <button class="btn btn-l btn-primary mt-2" @click="onSearch()">Tìm bệnh nhân</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card radius-10 pt-4 pl-4 pr-4 border-0">
        <div class="d-flex justify-content-end mb-3">
          <select name="" id="" v-model="has_advices" class="p-1 mr-1" @change="getPersonsDiseases()">
            <option :value="null">Tất cả</option>
            <option :value="0">Chưa có lời dặn</option>
            <option :value="1">Có lời dặn</option>
          </select>
          <select name="" id="" v-model="sort" class="p-1 mr-1" @change="getPersonsDiseases()">
            <option value="updated_at">Ngày cập nhật</option>
            <option value="created_at">Ngày tạo</option>
          </select>
          <select name="" id="" v-model="sort_by" class="p-1" @change="getPersonsDiseases()">
            <option value="desc">Mới nhất trước</option>
            <option value="asc">Cũ nhất trước</option>
          </select>
        </div>
        <div class="table-responsive Flipped">
          <Pagination class="Content" v-show="diseases && diseases.count" v-if="diseases" :items="diseases" :current_page="diseases.current_page" @onRefresh="getPersonsDiseases"></Pagination>
          <table class="table table-vcenter table-mobile-md card-table table-scroll Content" style="min-width: 1300px;">
            <thead>
              <tr>
                <th>Thông tin bệnh nhân</th>
                <th>Người tạo</th>
                <th>Bác sĩ</th>
                <th>Hồ sơ</th>
                <th>Tổng hợp</th>
                <th>Đơn thuốc</th>
                <th>Xét nghiệm</th>
                <th>Nhật ký</th>
                <th>Care team</th>
                <th>Ngày tạo</th>
                <th></th>
              </tr>
            </thead>
            <tbody v-if="diseases && diseases.data && !loading" class="h-100" style="min-height: 600px;">
              <tr v-for="(disease, index) in diseases.data" :set="change = disease.sign">
                <td data-title="Thông tin bệnh nhân">
                  <div class="d-flex py-1 align-items-center">
                    <div style="min-width: 40px; cursor: pointer;">
                      <span v-if="hasAvatar(disease && disease.person)" class="avatar avatar-sm avatar-rounded" :style="'background-image: url('+getImageURL(disease.person.avatar)+')'"></span>
                      <span v-if="!hasAvatar(disease && disease.person)" class="avatar avatar-sm avatar-rounded bg-secondary text-white">
                        {{ disease && disease.person && disease.person.name ? disease.person.name.charAt(0) : '' }}
                      </span>
                    </div>
                    <div class="flex-fill" style="cursor: pointer;">
                      <p class="p-0 m-0">{{disease && disease.person && disease.person.name ? disease.person.name : ''}}</p>
                      <small class="robo-12-400 text-black-50">{{disease && disease.person && disease.person.phone ? disease.person.phone : ''}}</small>
                    </div>
                  </div>
                </td>
                <td data-title="Người tạo">
                  <div class="d-flex py-1 align-items-center">
                    <!-- <div style="min-width: 40px; cursor: pointer;">
                      <span v-if="disease.created_by && disease.created_by.avatar" class="avatar avatar-sm avatar-rounded" :style="'background-image: url('+getImageURL(disease.created_by.avatar)+')'"></span>
                      <span v-if="!disease.created_by || (disease.created_by && !disease.created_by.avatar)" class="avatar avatar-sm avatar-rounded bg-secondary text-white">
                        {{ disease && disease.created_by && disease.created_by.name ? disease.created_by.name.charAt(0) : '' }}
                      </span>
                    </div> -->
                    <div class="flex-fill" style="cursor: pointer; overflow: hidden;" >
                      <p class="p-0 m-0 text-truncate" :title="disease && disease.created_by && disease.created_by.name ? disease.created_by.name : ''">
                        {{disease && disease.created_by && disease.created_by.name ? disease.created_by.name : ''}}
                      </p>
                      <div class="robo-12-400 text-black-50 text-truncate">{{disease && disease.created_by && disease.created_by.username ? disease.created_by.username : ''}}</div>
                    </div>
                  </div>
                </td>
                <td :set="doctor = getDoctor(disease)" data-title="Bác sĩ">
                  <div class="flex-fill">
                    <p class="p-0 m-0">{{doctor && doctor.doctor.name}}</p>
                    <span class="robo-12-400 text-muted">{{doctor && doctor.doctor.phone}}</span>
                  </div>
                </td>
                <td data-title="Hồ sơ">
                  <a href="javascript:;" @click="showCarePlaneDisease(disease)">{{disease && disease.diseases_name}}</a>
                </td>
                <td data-title="Tổng hợp">{{disease && disease.treatment_summary}}</td>
                <td data-title="Đơn thuốc">
                  <span v-if="change && change.medicine_flag === 1" class="badge badge-danger bg--red-corl text-red">New</span>
                </td>
                <td data-title="Xét nghiệm">
                  <span v-if="change && change.test_flag === 1" class="badge badge-danger bg--red-corl text-red">New</span>
                </td>
                <td data-title="Nhật ký">
                  <span v-if="change && change.diary_flag === 1" class="badge badge-danger bg--red-corl text-red">New</span>
                </td>
                <td data-title="Care team">
                  <span v-if="disease && disease.care_team" class="badge badge-danger bg--red-corl text-red"><a href="javascript:;" @click="onShowCareTeam(disease.care_team)">Link</a></span> 
                </td>
                <td data-title="Ngày tạo">
                  <span>{{disease && disease.created_at ? formatDDMMYYYY(disease.created_at) : ''}}</span>
                </td>
                <td>
                  <button class="btn btn-primary btn-sm mt-1" @click="gotoNotes(disease && disease.id)" style="width: 68px;">Note</button>
                  <button class="btn btn-primary btn-sm mt-1" :disabled="disease && disease.status !== 1" @click="postPersonsDiseasesStart(disease && disease.id)" style="width: 68px;">Start</button>
                  <button class="btn btn-primary btn-sm mt-1" @click="gotoDetail(disease && disease.id)">Chi tiết</button>
                </td>
              </tr>
            </tbody>
            <tbody v-if="loading" class="h-100" style="min-height: 600px;">
              <tr>
                <td rowspan="3" class="text-center py-5">
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <ModalCarePlan @onSubmit="onSubmit" :disease="disease"></ModalCarePlan>
    <ModalCareTeam :care_team="care_team"></ModalCareTeam>
    <ModelPersonDiseaseNotes :id_note="id_note"></ModelPersonDiseaseNotes>
  </div>
</template>
<script>
  import appUtils from '../../utils/appUtils'
  import ModalCarePlan from '../../components/Cms/ModalCarePlan'
  import Pagination from '../../components/Pagination'
  import ModalCareTeam from '../../components/Cms/ModalCareTeam'
  import ModelPersonDiseaseNotes from '../../components/Cms/ModelPersonDiseaseNotes.vue'
  export default {
    name: 'CareplanListing',
    components: {ModalCarePlan, Pagination, ModalCareTeam, ModelPersonDiseaseNotes},
    data () {
      return {
        diseases: null,
        loading: false,
        search: '',
        page: 1,
        disease: '',
        item: [],
        care_team: null,
        id_note: null,
        sort: 'updated_at',
        sort_by: 'desc',
        has_advices: null
      }
    },
    mounted () {
      let self = this
      self.getPersonsDiseases()
    },
    methods: {
      showCarePlaneDisease (disease) {
        let self = this
        self.disease = disease
        self.showCarePlane(true)
      },
      showCarePlane (is_show) {
        window.$('#model-care-plane').modal(is_show ? 'show' : 'hide')
      },
      getImageURL (avatar) {
        return appUtils.getImageURL(avatar)
      },
      hasAvatar (person) {
        return !!(person && person.avatar)
      },
      async getPersonsDiseases () {
        let self = this
        self.loading = true
        try {
          let params = {
            limit: 10,
            search: self.search,
            sort: self.sort || 'created_at',
            sort_by: self.sort_by || 'desc',
            page: parseInt(self.$route.query.page) || 1
          }
          if (self.has_advices !== null) {
            params.has_advices = self.has_advices ? 1 : 0
          }
          await self.$rf.getRequest('AdminRequest').getPersonsDiseases(params).then(res => {
            if (res && res.data) {
              self.diseases = res.data
              // self.diseases.data.forEach(function (element, index) {
              //   self.getPersonsCareplanSign(element)
              // })
            }
          })
        } catch (e) {
          // statements
          console.log(e)
        } finally {
          self.loading = false
        }
      },
      async postPersonsDiseasesStart (id) {
        let self = this
        try {
          await self.$rf.getRequest('AdminRequest').postPersonsDiseasesStart(id).then(res => {
            if (res && res.data) {
              self.getPersonsDiseases()
            }
          })
        } catch (e) {
          // statements
          console.log(e)
        }
      },
      getDoctor (disease) {
        if (disease && disease.care_team && disease.care_team.care_team_doctor) {
          var arr = disease.care_team.care_team_doctor
          var result = arr.find(item => item.is_accepted === 1 && item.role === 1)
          if (result) {
            return result
          } else {
            return null
          }
        }
      },
      async getPersonsCareplanSign (disease) {
        // let self = this
        // try {
        //   if (disease && disease.care_team && disease.care_team.person_diseases_id) {
        //     var params = {
        //       person_diseases_id: disease.care_team.person_diseases_id
        //     }
        //     var res = await self.$rf.getRequest('AdminRequest').getPersonsCareplanSign(params)
        //     if (res && res.data && res.data.data) {
        //       self.item.push(res.data.data)
        //       return self.item[self.item.length - 1]
        //     }
        //   }
        // } catch (e) {
        //   // statements
        //   console.log(e)
        // }
      },
      async onSubmit (id, data) {
        let self = this
        try {
          var params = {
            treatment_summary: data
          }
          await self.$rf.getRequest('AdminRequest').putPersonsDiseasesById(id, params).then(res => {
            self.getPersonsDiseases()
          })
        } catch (e) {
          console.log(e)
        }
      },
      onSearch () {
        this.$router.replace({query: {page: 1}}).catch(() => {})
        this.getPersonsDiseases()
      },
      onSearchDelete () {
        let self = this
        if (self.search.length === 0) {
          self.getPersonsDiseases()
        }
      },
      gotoDetail (id) {
        let self = this
        self.$router.push({path: `/admin/diseases/${id}/detail`})
      },
      showCareTeam (show) {
        window.$('#modal--care-team').modal(show ? 'show' : 'hide')
      },
      onShowCareTeam (care_team) {
        let self = this
        self.care_team = care_team
        self.showCareTeam(true)
      },
      showPersondiseasesNotes (show) {
        window.$('#modal--person-diseases-notes').modal(show ? 'show' : 'hide')
      },
      gotoNotes (id) {
        let self = this
        self.id_note = id
        self.showPersondiseasesNotes(true)
      },
      formatDDMMYYYY (date_time) {
        return window.moment(date_time).format('HH:mm DD/MM/YYYY')
      }
    }
  }
</script>
<style scoped="true" lang="css">
.Flipped, .Flipped .Content
{
    transform:rotateX(180deg);
    -ms-transform:rotateX(180deg); /* IE 9 */
    -webkit-transform:rotateX(180deg); /* Safari and Chrome */
}
</style>