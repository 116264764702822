<template>
    <div class="container-window mt-3 mr-3 border bd-e4 p-3">
        <HeaderWindow @onClose="onClose" :title="title" :show_calll="false" />
        <CallLogsTable @getCallLogs="getCallLogs" :current_page="current_page" :logs="call_logs"></CallLogsTable>
    </div>
</template>
<script>
import HeaderWindow from '../../components/Diseases/HeaderWindow.vue'
import CallLogsTable from '../../components/Cms/AdminCallLogsTable.vue'
export default {

  name: 'DiseaseCallLogs',
  components: {HeaderWindow, CallLogsTable},
  data () {
    return {
      title: 'lịch sử cuộc gọi',
      call_logs: null,
      person_diseases_id: '',
      current_page: 1
    }
  },
  mounted () {
    let self = this
    self.person_diseases_id = self.$route?.params?.id
    self.getCallLogs()
  },
  methods: {
    onClose () {
      this.$router.push({ path: `/admin/diseases/${this.$route?.params?.id}/topic` })
    },
    async getCallLogs () {
      let self = this
      self.current_page = parseInt(self.$route.query.page) || 1
      let params = {
        person_diseases_id: self.$route?.params?.id,
        limit: 13,
        sort: 'updated_at',
        sort_by: 'desc',
        page: self.current_page
      }
      try {
        await self.$rf.getRequest('AdminRequest').getCallLogs(params).then(res => {
          if (res && res.data) {
            self.call_logs = res.data
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    formatDateDMY (date) {
      return window.moment(date).format('DD/MM/YYYY')
    }
  }
}
</script>
<style lang="css" scoped>
</style>