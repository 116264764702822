<template>
  <div class="pr-3">
    <div class="telemedicine-management pr-3">
      <div class="row">
        <div class="col-12">
          <div class="d-flex py-1 align-items-center">
            <span class="txt-pri page-title">Thông tin</span>
          </div>
        </div>
      </div>
      <div class="card radius-10 pt-2 pl-4 pr-4 border-0">
        <div class="bound-border">
          <div class="p-10-10">
           <div class="row" style="padding: 51px 0 152px 0;">
            <div class="col-sm-4">
              <div class="d-flex justify-content-between align-items-center mt-2 card-item" :class="type == 1 ? 'bg-action' : 'bg-none-action'" @click="changeType(2)">
                <span class="robo-20-500">Tài khoản & bảo mật</span>
                <span class="icon-item"></span>
              </div>
            </div>
            <div class="col-sm-8">
              <div v-if="type==1">
                <ChangePassword></ChangePassword>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>
<script>
  import ChangePassword from '../../components/Doctor/ChangePassword.vue'
  export default {
    name: 'CmsInformation',
    // components: {DoctorInfo, ChangePassword},
    components: {ChangePassword},
    data () {
      return {
        loading: false,
        type: 1
      }
    },
    watch: {
    },
    mounted () {
    },
    methods: {
      changeType (type) {
        let self = this
        self.type = type
      }
    }
  }
</script>
<style scoped>
  .card-item {
    width: 80%; 
    height: 60px; 
    border-radius: 10px; 
    padding: 0px 16px 0 16px; 
  }
  .bg-none-action{
    background-color: #F2F5FA; 
    cursor: pointer;
  }
  .bg-action {
    background-color: #E5EEFE;
    cursor: default;
  }
</style>
