<template>
  <div class="container-window mt-3 mr-3 border bd-e4 px-3">
    <HeaderWindow
      @onClose="onClose"
      :title="title"
      :show_calll="true"
    />

    <div class="bg-white pt-3">
      <PersonProfile
        v-if="person_disease"
        :person="person_disease && person_disease.person"
        :disease="person_disease"
      ></PersonProfile>

    </div>
    <TypeBar
      :showing="show"
      :person_id="person_id"
      :disease="person_disease"
    />
    <TodoListTable
      v-if="!iss_add_new && person_disease"
      :page="page"
      :person_disease="person_disease"
      :advices="advices"
      @refresh="getAdvices"
    />
    <div v-if="!total">
        <p class="robo-18-500 text-center my-5">Chưa có lời dặn nào</p>
    </div>
  </div>
</template>
<script>
import PersonProfile from '../../components/Diseases/PersonProfile.vue'
import HeaderWindow from '../../components/Diseases/HeaderWindow.vue'
import TypeBar from '../../components/Cms/TypeBar.vue'
import TodoListTable from '../../components/Diseases/Todo/TodoListTable.vue'
export default {

  name: 'TodoList',
  components: { PersonProfile, HeaderWindow, TypeBar, TodoListTable },
  data () {
    return {
      type: 1,
      show: 'advices',
      title: 'Hồ Sơ Sức Khỏe',
      person_id: null,
      person: null,
      disease: null,
      person_disease: null,
      monitor_stats: null,
      iss_add_new: false,
      page: 1,
      total: 0,
      advices: []
    }
  },
  mounted () {
    let self = this
    self.getPersonsDiseasesDetail()
    self.getStats()
  },
  watch: {
    '$route.query.page': {
      handler: function (page) {
        this.page = page || 1
        this.getAdvices()
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    onClose () {
      this.$router.push({ path: `/admin/diseases/${this.$route?.params?.id}/detail` })
    },
    async getPersonsDiseasesDetail () {
      let self = this
      try {
        await self.$rf.getRequest('AdminRequest').getPersonsDiseasesById(self.$route?.params?.id).then(res => {
          if (res && res.data) {
            self.person_disease = res.data
            self.person_id = self.person_disease.person_id
            self.getAdvices()
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    async getStats () {
      let self = this
      let params = {}
      self.monitor_stats = []
      await self.$rf.getRequest('AdminRequest').getSurvivalStatSetting(self.$route?.params?.id, params).then(resp => {
        if (resp && resp.data && resp.data.count) {
          let stats = resp.data.data
          stats.forEach(function (stat, index) {
            if (stat.is_monitor === 1) {
              self.monitor_stats.push(stat)
            }
          })
        }
      })
    },
    async getAdvices () {
      let self = this
      if (!self.$route?.params?.id) return
      let params = {
        limit: 15,
        page: self.page
      }
      await self.$rf.getRequest('AdminRequest').getAdvices(self.$route?.params?.id, params).then(resp => {
        self.advices = resp?.data
        self.total = resp?.data?.total
      })
    }
  }
}
</script>
<style lang="css" scoped>
.btn-add-new {
  height: 50px;
  border-radius: 0;
}
</style>