<template>
  <div class="row">
    <div class="telemedicine-management pr-3">
      <div class="row pl-3">
        <div class="col-12">
          <div class="d-flex py-1 align-items-center">
            <span class="txt-pri page-title">Danh sách Workspace đăng ký</span>
          </div>
        </div>
      </div>
      <div class="card radius-10 pt-4 pl-4 pr-4 border-0">
        <div class="table-responsive">
          <table class="table table-vcenter table-mobile-md card-table table-scroll">
            <thead>
              <tr class="d-sm-flex">
                <th class="col-sm-2">Name</th>
                <th class="col-sm-2">Contact</th>
                <th class="col-sm-2">Email</th>
                <th class="col-sm-2">Date</th>
                <th class="col-sm-2">Status</th>
                <th class="col-sm-2"></th>
              </tr>
            </thead>
            <tbody v-if="clinics && clinics.count && clinics.data" class="h-100">
              <tr v-for="clinic in clinics.data" :key="clinic.id" class="d-sm-flex">
                  <td data-label="Name" class="col-sm-2">
                    {{ clinic.name }}
                  </td>
                  <td data-label="Contact" class="col-sm-2">
                    <div>{{ clinic.phone }}</div>
                    <div class="text-muted text-h5">{{ clinic.address }}</div>
                  </td>
                  <td data-label="Email" class="col-sm-2">
                    <div>{{clinic.email }}</div>
                  </td>
                  <td data-label="Date" class="col-sm-2">
                    {{getDateTime(clinic.created_at)}}
                  </td>
                  <td class="text-muted col-sm-2" data-label="Status">
                    <span v-if="clinic.state === 1 || clinic.state == null" class="text-warning">Pending</span>
                    <span v-if="clinic.state === 2" class="text-success">Accepted</span>
                    <span v-if="clinic.state === 3" class="text-danger">Rejected</span>
                  </td>
                  <td class="col-sm-2">
                    <a v-if="clinic.state === 1 || clinic.state == null" href="javascript:;" class=" h2 d-inline m-1 icon-action-table" @click="acceptClinic(clinic.id)">
                      <button class="btn btn-sm btn--bg-success btn--person">
                        Accept
                      </button>
                    </a>
                    <a v-if="clinic.state === 1 || clinic.state == null" href="javascript:;" class=" h2 d-inline m-1 icon-action-table" @click="rejectClinic(clinic.id)">
                      <button class="btn btn-sm btn--bg-danger btn--person">
                        Reject
                      </button>
                    </a>
                  </td>
                </tr>
            </tbody>
          </table>
          <Pagination v-if="clinics" :items="clinics" :current_page="current_page" @onRefresh="getClinics"></Pagination>
        </div>
        <div v-if="clinics && !clinics.count" class="row">
          <div class="col-sm-4 offset-sm-4 pt-3 pb-5 text-center">
            <img src="../../../public/assets/images/not-found-search.png" class="img-responsive p-0" alt="">
            <p class="robo-16-400 text-black">{{search ? 'HODO không tìm thấy thẻ tag mà bạn tìm' : 'Chưa có thẻ tag nào!' }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Pagination from '../../components/Pagination'

  export default {
    name: 'ClinicAppliesListing',
    components: {Pagination},
    data () {
      return {
        clinics: null,
        current_page: 1,
        search: ''
      }
    },
    mounted () {
      let self = this

      self.getClinics()
    },
    methods: {
      async getClinics () {
        let self = this
        self.current_page = parseInt(self.$route.query.page) || 1
        let params = {
          page: self.current_page
        }
        if (self.search) {
          params.search = self.search
        }

        let resp = await self.$rf.getRequest('AdminRequest').getClinicApplies(params)
        self.clinics = resp.data
      },
      async acceptClinic (id) {
        let self = this
        var r = confirm('Do you want to accept this clinic?')
        if (!r) {
          return false
        }
        let params = {
          state: 2
        }
        await self.$rf.getRequest('AdminRequest').acceptClinicApplies(id, params)
      },
      async rejectClinic (id) {
        let self = this
        var r = confirm('Do you want to reject this clinic?')
        if (!r) {
          return false
        }
        let params = {
          state: 3
        }
        await self.$rf.getRequest('AdminRequest').rejectClinicApplies(id, params)
      },
      getDateTime (date_time) {
        return window.moment(date_time).format('DD-MM-YYYY HH:mm')
      }
    }
  }
</script>
