<template>
  <div class="row">
    <div class="telemedicine-management pr-3 pt-3">
      <div class="card radius-10 pt-4 pl-4 pr-4 border-0">
        <div class="d-flex justify-content-end mb-3">
          <select
            name=""
            id=""
            v-model="is_redeem"
            class="form-select border mr-3 w-170px"
            @change="getPersons()"
          >
            <option :value="null">Tất cả</option>
            <option :value="1">Sắp hết hạn</option>
          </select>
          <select
            name=""
            id=""
            v-model="is_redeem"
            class="form-select border mr-3 w-170px"
            @change="getPersons()"
          >
            <option :value="null">Tất cả</option>
            <option :value="1">Đã sử dụng</option>
          </select>
          <div class="input-group mr-2">
            <input
              class="h-100 border form-control"
              type="text"
              name=""
              v-model="search"
              @keyup.enter="searchStr()"
              @keyup.delete="searchStrDelete()"
            ><button
              class="input-group-text btn btn-light"
              @click="searchStr()"
            >Tìm kiếm</button>
          </div>
          <div class="input-group mr-2">
            <button
              class="btn bg-pri bd-pri text-white h-80"
              @click="downloadFile()"
            ><i
                class="fa fa-download"
                aria-hidden="true"
              ></i>Tải xuống</button>
          </div>
        </div>
      </div>
    </div>
    <div class="card radius-10 pt-2 pl-4 pr-4 border-0">
      <div class="table-responsive">
        <table class="table table-vcenter table-mobile-md card-table table-scroll">
          <thead>
            <tr>
              <th>Thông tin bệnh nhân</th>
              <th>Số điện thoại</th>
              <th>Tình trạng</th>
              <th>Thành viên</th>
              <th>Package redeem</th>
              <th>Last login</th>
              <th>Địa chỉ</th>
            </tr>
          </thead>
          <tbody
            v-if="persons && persons.count && persons.data"
            class="h-100"
          >
            <tr
              v-for="person in persons.data"
              :key="person.id"
            >
              <td
                data-label="Thông tin bệnh nhân"
                class="table-td-name-avatar"
              >
                <div class="d-flex py-1 align-items-center">
                  <div style="min-width: 40px;">
                    <span
                      v-if="hasAvatar(person.avatar)"
                      class="avatar avatar-sm avatar-rounded"
                      :style="'background-image: url('+getImageURL(person.avatar)+')'"
                    ></span>
                    <span
                      v-if="!hasAvatar(person.avatar)"
                      class="avatar avatar-sm avatar-rounded bg-secondary text-white"
                    >
                      {{ person && person.person && person.person.name ? person.person.name.charAt(0) : '-' }}
                    </span>
                  </div>
                  <div class="flex-fill">
                    <p class="p-0 m-0">{{person && person.person && person.person.name ? person.person.name : ''}}</p>
                  </div>
                </div>
              </td>
              <td>{{person ? person.username ? person.username : '' : ''}}</td>
              <td>
                <div
                  class="text-green"
                  v-if="person.is_redeem === 1"
                >
                  Đã sử dụng
                </div>
                <div
                  class="text-blue"
                  v-if="person.is_redeem != 1  && person.is_loyalty_member === 1"
                >
                  Chưa sử dụng
                </div>
              </td>
              <td>
                <div
                  class="text-blue"
                  v-if="person.is_loyalty_member === 1"
                >
                  Trung thành
                </div>
              </td>
              <td>{{person.package ? person.package.name ? person.package.name : '' : ''}}</td>
              <td v-html="person ? person.last_login ? formatWeekdayTimev2(person.last_login)  : '' : ''"></td>
              <td>{{person && person.person && person.person.address ? person.person.address : ''}}</td>
            </tr>
          </tbody>
        </table>
        <Pagination
          v-show="persons && persons.count"
          v-if="persons"
          :items="persons"
          :current_page="current_page"
          @onRefresh="getPersons"
        ></Pagination>
      </div>
      <div
        v-if="persons && !persons.count && !loading"
        class="row"
      >
        <div class="col-sm-4 offset-sm-4 pt-3 pb-5 text-center">
          <img
            src="../../../public/assets/images/not-found-search.png"
            class="img-responsive p-0"
            alt=""
          >
          <p class="robo-16-400 text-black">{{search ? 'HODO không tìm thấy bệnh nhân mà bạn tìm' : 'Chưa có bệnh nhân nào!' }}</p>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>
<script>
import appUtils from '../../utils/appUtils'
import Pagination from '../../components/Pagination'
import { ExportToCsv } from 'export-to-csv'
export default {
  name: 'ContactListAdmin',
  components: { Pagination },
  data () {
    return {
      persons: null,
      loading: false,
      is_redeem: null,
      params: {
        limit: 30,
        search: '',
        sort: 'created_at',
        sort_by: 'desc'
      },
      search: '',
      current_page: 0,
      page: 1
    }
  },
  mounted () {
    this.page = this.$route.query.page ? this.$route.query.page : 1
    this.getPersons()
  },
  watch: {
    '$route.query.type': {
      handler: function () {
        let self = this
        self.page = self.$route.query.page
        self.getPersons()
      },
      deep: true
    }
  },
  methods: {
    async getPersons () {
      try {
        let self = this
        self.current_page = parseInt(self.$route.query.page) || 1
        self.params = {
          limit: 30,
          search: self.search,
          sort: 'created_at',
          sort_by: 'desc',
          page: self.current_page
        }
        if (self.is_redeem !== null) {
          self.params.is_redeem = self.is_redeem ? 1 : 0
        }
        const result = await self.$rf.getRequest('AdminRequest').getUser(self.params)
        self.persons = result.data
        self.current_page = result.data.current_page
      } catch (e) {
        console.log(e)
      } finally {

      }
    },
    hasAvatar (person) {
      return !!(person && person.avatar)
    },
    getImageURL (avatar) {
      return appUtils.getImageURL(avatar)
    },
    formatWeekdayTimev2 (date_time) {
      let curr_week = window.moment().week()
      let date_time_week = window.moment(date_time).week()
      if (curr_week === date_time_week) {
        return window.moment(date_time).fromNow()
      } else {
        var split_time = window.moment(date_time).format('DD/MM/YYYY HH:mm ').split(' ')
        var str_time = split_time[1] + '<br><small class="robo-12-400 text-muted">' + split_time[0] + '</small>'
        return str_time
      }
    },
    searchStr () {
      let self = this
      self.$route.query.page = 1
      self.getPersons()
      self.$router.push({ name: self.$router.currentRoute.name, query: { page: self.page } }).catch(() => { })
    },
    searchStrDelete () {
      let self = this
      if (self.search.length === 0) {
        self.$route.query.page = 1
        self.getPersons()
        self.$router.push({ name: self.$router.currentRoute.name, query: { page: self.page } })
      }
    },
    async downloadFile () {
      let self = this
      try {
        if (self.persons) {
          console.log(self.persons)
          var data = []
          var params = {
            limit: self.persons.total,
            order: 'desc',
            order_by: 'created_at'
          }
          var list_result = await self.$rf.getRequest('AdminRequest').getUser(params)
          if (list_result.data.total > 0) {
            list_result.data.data.forEach((item, index) => {
              var name = item && item.person && item.person.name ? item.person.name : ''
              var age = item && item.person && item.person.age ? item.person.age : ''
              var phonenumber = item && item.username ? item.username : ''
              var career = item && item.person && item.person.career ? item.person.career : ''
              var otp = item && item.otp ? item.otp : ''
              var lasttime = item && item.last_login ? item.last_login : ''
              var address = item && item.person && item.person.address ? item.person.address : ''
              var createat = item && item.created_at ? item.created_at : ''
              let obj = {
                'STT': index + 1,
                'Họ tên bệnh nhân': name,
                'Tuổi': age,
                'Số điện thoại': phonenumber,
                'Nghề nghiệp': career,
                'OTP': otp,
                'Địa chỉ': address,
                'Last login': lasttime,
                'Create at': createat
              }
              data.push(obj)
            })
          }
          let file_names = []
          file_names.push('report_persons')
          const options = {
            filename: file_names.join('_'),
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: false,
            title: 'Transactions Export',
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true
          }
          const csvExporter = new ExportToCsv(options)
          return csvExporter.generateCsv(data)
        }
      } catch (e) {

      }
    }
  }
}
</script>
<style scoped></style>